import React from "react";
import { strings, sections } from "./menuStrings";
import "./menu.css";

const MenuItem = (props) => {
  return <p className="menu-item">{props.itemName}</p>;
};

const MenuSection = (props) => {
  return (
    <>
      <p className="heading-3">{strings["starters"]}</p>
      <ul>
        {props.sections.starters.map((item, i) => (
          <li className={props.bulletSelector} key={i}>
            <MenuItem itemName={item} />
          </li>
        ))}
      </ul>
      <p className="heading-3">{strings["mains"]}</p>
      <p>All mains are served with potatoes and seasonal vegetables.</p>
      <ul>
        {props.sections.mains.map((item, i) => (
          <li className={props.bulletSelector} key={i}>
            <MenuItem itemName={item} />
          </li>
        ))}
      </ul>
      <p className="heading-3">Dessert</p>
      <ul>
        {props.sections.desserts.map((item, i) => (
          <li className={props.bulletSelector} key={i}>
            <MenuItem itemName={item} />
          </li>
        ))}
      </ul>
    </>
  );
};

export const Menu = (props) => {
  return (
    <span className="menu-content">
      <h1 id="Menu" className="heading">
        Menu
      </h1>
      <h2 className="heading-2"> {strings["adults"]}</h2>

      <MenuSection
        sections={sections.adults}
        bulletSelector="purple-point"
      ></MenuSection>
      <h2 className="heading-2"> {strings["childrens"]}</h2>
      <MenuSection
        sections={sections.children}
        bulletSelector="purple-point"
      ></MenuSection>
      <h2 className="heading-2">{strings["dietary"]}</h2>
      <MenuSection
        sections={sections.dietary}
        bulletSelector="purple-point"
      ></MenuSection>

      <div className="note">
        {" "}
        <p>
          You are welcome to mix and match between the adult and dietary menus.
          Please let us know by text or email.
        </p>
      </div>
    </span>
  );
};

import React from "react";
import "./location.css";
export const Location = (props) => {
  return (
    <span className="location-content">
      <h1 id="Location" className="heading">
        Location
      </h1>
      <img
        id="zooMap"
        alt="map of how to get to the zoo."
        src={require("../images/ZOO_MAP.png")}
      />
      <p className="caption"> How to get to the Mansion House </p>

      <ol>
        <li>From the Zoo's entrance you can head right to the car park.</li>
        <li>Follow the hill to the top of the parking lot, there will be toilets and the Members gate.</li>
        <li>To enter, please mention that you are here for Darren and Lauras wedding.</li>
        <li>Follow the hill, past the keeper experience, keeping to the right hand side of the manor house.</li>
        <li>Enter past the pond to the entrance behind the Mansion House.</li>
       </ol>

       <p>
        Other Information:
       </p>
       <ul>
         <li className="sakura-point">All day guests have access to the zoo, and can take beverages purchased at the Mansion House outside, once they have been swapped to outdoor containers.</li>
         <li className="sakura-point">The Mansion House does have stairs, and the ceremony will take place up stairs, as well as access to the evening buffet. Please let us know if this is an issue.</li>
         <li></li>
         <li></li>
         <li></li>
         <li></li>
         <li></li>
         <li></li>
       </ul>
    </span>
  );
};

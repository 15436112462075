import "./App.css";
import { Card } from "./card/card";
import { Menu } from "./menu/menu";
import { Location } from "./location/location";
import { NavBar } from "./nav/navbar";
import { Faq } from "./faq/faq";

function App() {
  return (
    <div className="App">
      <div id="wrapper">
        <div id="mobile-header"></div>
        <NavBar className="navBar"></NavBar>
        <div id="inner-wrapper">
          <div id="header"></div>
          <Card children={<Menu />} flowerClass="purple-flowers" />
          <Card children={<Location />} flowerClass="sakura-flowers" />
          <Card children={<Faq />} flowerClass="peony-flowers" />
        </div>
      </div>
    </div>
  );
}

export default App;

import React from "react";
import "./card.css";

export const Card = (props) => {
  return (
    <div className="card-outer">
      <div className="card">
        <div className="card-border">
          <div className="card-inner">{props.children}</div>
        </div>
        <div className={`${props.flowerClass}-top`}></div>
        <div className={`${props.flowerClass}-bottom`}></div>
      </div>
    </div>
  );
};

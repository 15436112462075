import React from "react";
import "./navbar.css";

export const NavBar = (props) => {
  return (
    <div className="nav-outer">
      <div className="nav-button-1">
        <a href="#Menu">Menu</a>
      </div>
      <div className="nav-button-2">
        <a href="#Location">Location</a>
      </div>
      <div className="nav-button-3">
        <a href="#FAQ">F.A.Q</a>
      </div>
    </div>
  );
};

import React from "react";
import "./faq.css";

export const Faq = (props) => {
  return (
    <span className="faq-content">
      <h1 id="FAQ" className="heading">
        F.A.Q
      </h1>

      <ul>
        <li className="faq-point">
          <p>Q: Can I use cash at the bar?</p>
          <p>
            A: No, it is card only. Please let us know if this is an issue, and
            we can
          </p>
        </li>
        <li className="faq-point">
          <p>Q: Will Darren be wearing a kilt?</p>
          <p>A: Yes, please feel free to wear one if you'd like.</p>
        </li>
        <li className="faq-point">
          <p>Q: What should I wear?</p>
          <p>
            A: Please wear something formal, but comfortable as there will be a
            ceilidh later in the evening.
          </p>
        </li>
        <li className="faq-point">
          <p>Q: Can I mix and match dietary and adult courses?</p>
          <p>
            A: Yes, we were originally told this was not possible, but we’ve
            since been told that this can be done if you would like to do this!
          </p>
        </li>
        <li className="faq-point">
          <p>Q: Can I RSVP online?</p>
          <p>
            A: Yes, if you'd like to RSVP by email or phone please get in touch
            with Darren or Laura.
          </p>
        </li>
        <li className="faq-point">
          <p>Q: How do I get to the Zoo?</p>
          <p>
            A: You can find information on how to get to the Zoo{" "}
            <a href="https://www.edinburghzoo.org.uk/plan-your-visit/day-planner/how-to-get-here/">
              here
            </a>
            .
          </p>
        </li>
        <li className="faq-point">
          <p>Q: Where can I stay nearby?</p>
          <p>
            A: There is a Holiday Inn next door to the Zoo. You can find more
            information{" "}
            <a href="   https://www.ihg.com/holidayinn/hotels/gb/en/edinburgh/edbcr/hoteldetail">
              here
            </a>
            . <br />
            There are also several other hotels and bed and breakfasts nearby.{" "}
            <br /> <br /> You can see them on Google Maps{" "}
            <a href="https://www.google.com/maps/search/Hotels/@55.9423796,-3.2773486,15z/data=!3m1!4b1!4m12!2m11!3m6!1sHotels!2sEdinburgh+Zoo,+Royal+Zoological+Society+of+Scotland,+134+Corstorphine+Rd,+Corstorphine,+Edinburgh+EH12+6TS!3s0x4887c661482ca367:0xdab93fa996cd02cb!4m2!1d-3.2685938!2d55.9423619!5m2!5m1!1s2022-06-19!6e3">
              here
            </a>
            .
          </p>
        </li>
        <li className="faq-point">
          <p>
            Q: I'd prefer to give you a physical gift, is there a way I can do
            this?
          </p>
          <p>
            A: As a reminder, we are super grateful for all gifts that we're
            given, but we don't expect any. We are currently making an amazon
            wishlist that we can share with anyone that would prefer it.
          </p>
          <br />
          <p>
            If you'd like to contribute to our new home fund please use the{" "}
            <a href="https://monzo.me/darrenstewart2">Monzo Link</a> or cash on
            the day.
          </p>
        </li>
      </ul>
    </span>
  );
};

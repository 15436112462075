export const strings = {
  adults: "Adult’s Menu",
  starters: "Starter",
  goatsCheese:
    "Goat’s cheese & caramelised onion tart, served on a bed of peppery rocket, vine tomatoes and a balsamic syrup",
  haggis:
    "Haggis, neeps and tatties tower accompanied with Drambuie café au lait",
  mains: "Mains",
  beef: "Braised topside of Scottish beef with Yorkshire Pudding, baby onions and rosemary gravy",
  chicken: "Roast chicken breast served with a mushroom & thyme jus",
  dessert: "Dessert",
  toffeePudding:
    "Sticky toffee pudding served with butterscotch sauce and vanilla ice cream",
  childrens: "Children’s Menu",
  soup: "Soup of the day",
  chickenChunks: "Home-made chicken chunks in breadcrumbs chips & beans",
  pizza: "Cheese & tomato pizza, chips & beans",
  icecream: "Selection of ice creams",
  dietary: "Dietary Menu",
  mixNote:
    "You are welcome to mix and match between the adult and dietary menus. Please let us know by text or email.",
  mozz: "Mozzarella with vine ripened tomatoes and fresh basil",
  risotto:
    "Creamy risotto of asparagus and truffle oil finished with vegan parmesan",
  sorbet: "Trio of refreshing fruit sorbets",
};

export const sections = {
  adults: {
    starters: [strings.goatsCheese, strings.haggis],
    mains: [strings.beef, strings.chicken],
    desserts: [strings.toffeePudding],
  },
  children: {
    starters: [strings.soup],
    mains: [strings.chickenChunks, strings.pizza],
    desserts: [strings.icecream],
  },
  dietary: {
    starters: [strings.mozz],
    mains: [strings.risotto],
    desserts: [strings.sorbet],
  },
};
